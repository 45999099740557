import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Nav from './Navigation';
import axios from '../api/axios';


import { Fragment } from 'react';
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon,
  DocumentIcon
} from '@heroicons/react/20/solid';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import Media from './Media';
import MediaPanel from './MediaPanel';
import Notification from './Notificaiton';
import Modal from './Modal';
import { socket } from '../socket';
import useAuth from '../hooks/useAuth';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

async function updateConversation(object){
  axios.patch(`/conversation/${object._id}`, object)
      .then(res => console.log(res.data))
      .catch(error => {
        console.log(error);
  });
}

/* function formatDate(dateString) {
  return new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // User's local timezone
  }).format(new Date(dateString));
}
 */

function formatDate(dateString) {
  if (!dateString) return ''; // Quick fix: return empty string if dateString is undefined or null

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return ''; // Prevents RangeError if the date is invalid

  return new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // User's local timezone
  }).format(date);
}


function Message({ type, media, body, date, setMediaTrue, setMedia, mediatype }) {
  const messagePosition = type === 'outbound' ? 'flex justify-start' : 'flex justify-end';
  const imagePosition = type === 'outbound' ? 'mr-auto flex justify-start rounded-md w-full max-w-32 cursor-pointer' : 'ml-auto flex justify-end rounded-md w-full max-w-32 cursor-pointer';
  
  // State to track whether the image has loaded
  const [imageLoaded, setImageLoaded] = useState(false);

  if (media && mediatype === 'application/pdf') {
    return (
      <>
        <div className={messagePosition}>
          <a href={media} target='_blank'>
            <div className="cursor-pointer hover:opacity-75 bg-slate-200 rounded-md h-32 w-24 pt-5">
              <DocumentIcon className="text-slate-400 relative h-12 w-12 mx-auto" aria-hidden="true" />
              <p className="text-sm text-center">Document</p>
              <p className="text-xs text-center truncate overflow-hidden mx-2">type: .pdf</p>
            </div>
          </a>
        </div>
        <p className={"text-right text-xs mt-2 tracking-tight"}>{formatDate(date)}</p>
      </>
    );
  } else if (media) {
    return (
      <div className={messagePosition}>
        <div>
          {/* Placeholder image shown while loading */}
          {!imageLoaded && (
            <img

              className={imagePosition+" max-w-8 mx-14 my-14"}
              src="https://i.gifer.com/ZKZg.gif" // Replace with your placeholder image
              alt="Placeholder"
            />
          )}
          {/* Actual image, will replace placeholder once loaded */}
          <img
            onLoad={() => setImageLoaded(true)} // Update state when image is loaded
            style={{ display: imageLoaded ? 'block' : 'none' }} // Hide placeholder when actual image is loaded
            onClick={() => { setMediaTrue(true); setMedia(media) }}
            className={`${imagePosition} ${imageLoaded ? '' : 'hidden'}`} // Hide actual image until loaded
            src={media}
            alt="Message"
          />
          <p className={"text-right text-xs mt-2 tracking-tight"}>{formatDate(date)}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={messagePosition}>
        <div className={type === 'outbound' ? 'bg-blue-500 text-white p-3 rounded-lg max-w-xs' : 'bg-gray-200 text-black p-3 rounded-lg max-w-xs'}>
          <p>{body}</p>
          <p className="text-right text-xs mt-2 tracking-tight">{formatDate(date)}</p>
        </div>
      </div>
    );
  }
}

// Main Chat component
function Chat() {

  socket.on("connect_error", (err) => {
    console.log(`connect_error due to ${err.message}`);
  });

  const {auth} = useAuth();

  const [user,setUser] = useState({});

  useEffect(() => {
    if(auth){
      setUser(auth);
    }
  }, [auth])

  const [isConnected, setIsConnected] = useState(socket.connected);
  const conversationId = window.location.pathname.split('/').pop();
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0 });
  const messagesEndRef = useRef();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState({});

  const [mediaTrue, setMediaTrue] = useState(false);
  const [media, setMedia] = useState('');

  const [mediaPanelTrue, setMediaPanelTrue] = useState(false);
  const [allMedia, setAllMedia] = useState([]);

  const [notificationObject, setNotificationObject] = useState({});
  const [notification, setNotification] = useState(false);

  const [errorTrue, setErrorTrue] = useState(true);
  const [error, setError] = useState('');

  const [modal, setModal] = useState(false);
  const [template, setTemplate] = useState(false);

  
  useEffect(() => {
    socket.connect();

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    socket.io.on("error", (error) => {
      console.log(error);
    });

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.disconnect();
    };
  }, []);
  

  // Fetch messages and conversation data on component mount
  useEffect(() => {
    // Define an async function to fetch messages and conversation data
    const fetchData = async () => {
      try {
        // Make both requests simultaneously using Promise.all
        const [messagesResponse, conversationResponse] = await Promise.all([
          axios.get(`/conversation/${conversationId}/messages`),
          axios.get(`/conversation/${conversationId}`)
        ]);

        // Filter messages with mediaurl
        const messagesWithMedia = messagesResponse.data.filter(message => message.mediaurl);

        // Set messages, conversation, and allMedia states
        setMessages(messagesResponse.data);
        setConversation(conversationResponse.data);
        setAllMedia(messagesWithMedia);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Call the fetchData function
    fetchData();

    // Cleanup function (optional)
    return () => {
      // Any cleanup code if needed
    };
  }, [conversationId]); // Ensure useEffect runs only when conversationId changes


  // Subscribe to socket room when conversation data changes
  useEffect(() => {
    if (socket && conversation) {
      const roomName = `${conversation.customer}_${conversation.sender}`;

      const newMessage = data => {
        setMessages(prevMessages => [...prevMessages, data]);
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      };

      socket.on(roomName, newMessage);

      return () => {
        socket.off(roomName, newMessage);
      };
    }
  }, [conversation]);

  

  useEffect(() => {
    if (conversation.sessioncreatedat) {
      const now = new Date();
      const startDateTime = new Date(conversation.lastreceived);
      const timeDiff = Math.floor((startDateTime.getTime() + 24 * 60 * 60 * 1000 - now.getTime()) / (60 * 1000)); // 24 hours from start date in minutes
      
      if (timeDiff > 0 && conversation.session) {
        const hours = Math.floor(timeDiff / 60);
        const minutes = timeDiff % 60;
        setTimeLeft({ hours, minutes });
      } else {
        setTimeLeft({ hours: 0, minutes: 0 });
      }

      const interval = setInterval(() => {
        const now = new Date();
        const timeDiff = Math.floor((startDateTime.getTime() + 24 * 60 * 60 * 1000 - now.getTime()) / (60 * 1000)); // Recalculate time difference in minutes
        
        if (timeDiff > 0) {
          const hours = Math.floor(timeDiff / 60);
          const minutes = timeDiff % 60;
          setTimeLeft({ hours, minutes });
        } else {
          clearInterval(interval);
          setTimeLeft({ hours: 0, minutes: 0 });
        }
      }, 60000); // Change interval to 1 minute

      return () => clearInterval(interval);
    }
  }, [conversation]);

  useEffect(() => {
    if(conversation && conversation.agent && !conversation.agent.includes(user?.username)){
      console.log('setting notification to true');
      setNotification(true);
      setNotificationObject({
        notification: 'info',
        title: 'Conversation added!',
        message: 'This conversation will appear in your inbox.'
      });
      let tempConversation = conversation;
      tempConversation.agent.push(user?.username);
      tempConversation.unread = false;
      setConversation({ ...tempConversation});
      updateConversation(conversation);
    }
  }, [conversation,user])

  useEffect(() => {
    if (conversation._id) { // Ensure conversation object is not empty
      let updatedConversation = { ...conversation };
      updatedConversation.unread = false;
      if(conversation.status == "new"){
        updatedConversation.status = null;
      }
      setConversation(updatedConversation);
      updateConversation(updatedConversation);
    }
  }, [conversation._id]);

  useEffect(() => {
    if(template){
      const templateObject = {
        id: conversationId,
        agent: user?.firstname,
        sender: conversation.sender,
        customer: conversation.customer,
        service: conversation?.service || null,
        companyId: user?.companyId,
        name: conversation.name
      };
      axios.post(`/template`, templateObject)
        .then(res => console.log(res.data))
        .catch(error => {
          console.log(error);
      });
    }
  }, [template])

  // Handler for form submission
  const handleSubmit = e => {
    e.preventDefault();
    if(!conversation.session){
      setModal(true);
    } else {
      if(conversation.channel == "WhatsApp"){
        const messageObject = {
          sender: conversation.sender,
          customer: conversation.customer,
          status: "sent",
          type: "outbound",
          body: message,
          companyId: user?.companyId,
          agent: user?.username,
        };
        axios.post(`/messages`, messageObject)
          .then(res => console.log(res.data))
          .catch(error => {
            console.log(error);
        });
        setMessage('');
      } else if(conversation.channel == "iMessage") {
        const messageObject = {
          sender: conversation.sender,
          customer: conversation.customer,
          status: "sent",
          type: "outbound",
          body: message,
          companyId: user?.companyId,
          agent: user?.username,
        };
        axios.post(`/messages/imessage`, messageObject)
          .then(res => console.log(res.data))
          .catch(error => {
            console.log(error);
        });
        setMessage('');
      }
      
    }
  };

  // Handler for message input change
  const handleChange = e => {
    setMessage(e.target.value);
  };

  const openMediaPanel = e => {
    setMediaPanelTrue(true);
  };

  // Handler for pressing Enter key in message input
  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <Nav 
    name={conversation.name} 
    channel={conversation.channel} 
    hours={timeLeft.hours} 
    minutes={timeLeft.minutes}
    openMediaPanel={openMediaPanel}
    conversationObject={conversation}
    >
      <Modal modal={modal} onSend={() => setTemplate(true)}/>
      <Notification notification={notification} notificationObject={notificationObject} />
      <MediaPanel state={mediaPanelTrue} media={allMedia} onClose={() => setMediaPanelTrue(false)}/>
      <Media state={mediaTrue} media={media} onClose={() => setMediaTrue(false)}/>
      <div className="opacity-100">
        <div className="flex flex-col space-y-4">
          {messages.map(message => (
            <Message
            key={message._id}
            type={message.type}
            media={message.mediaurl}
            body={message.body}
            date={message.createdAt}
            mediatype={message.mediatype}
            setMediaTrue={setMediaTrue}
            setMedia={setMedia}
            />
          ))}
        </div>
        <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex items-start space-x-4">
            <div className="flex-shrink-0">
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                <span className="text-sm font-medium leading-none text-white">{user?.firstname ? user?.firstname[0] + user?.lastname[0]: '..'}</span>
              </span>
            </div>
            <div className="min-w-0 flex-1">
              <form onSubmit={handleSubmit} className="relative">
                <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
                  <label htmlFor="comment" className="sr-only">
                    Write your message...
                  </label>
                  <textarea
                    rows={3}
                    name="message"
                    id="message"
                    className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Write your message..."
                    value={message} // Set the value from the state
                    onChange={(e) => setMessage(e.target.value)} // Handle the change
                    onKeyDown={handleKeyDown}
                    //disabled
                  />

                  {/* Spacer element to match the height of the toolbar */}
                  <div className="py-2" aria-hidden="true">
                    {/* Matches height of button in toolbar (1px border + 36px content height) */}
                    <div className="py-px">
                      <div className="h-9" />
                    </div>
                  </div>
                </div>

                <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
                  <div className="flex items-center space-x-5">
                    {/*
                    <div className="flex items-center">
                      <button
                        type="button"
                        className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                      >
                        <PaperClipIcon className="h-5 w-5" aria-hidden="true" title="feature coming soon"/>
                        <span className="sr-only">Attach a file</span>
                      </button>
                    </div>
                    */}
                    <div className="flex items-center">
                      
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="submit"
                      className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      <PaperAirplaneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div ref={messagesEndRef} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </Nav>
  );
}

export default Chat;