import { Link, useLocation, useNavigate } from 'react-router-dom';
import { React, Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import useLogout from '../hooks/useLogout';
import useAuth from '../hooks/useAuth';
import logo from '../images/logo_light.png';

import axios from '../api/axios';
import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  QuestionMarkCircleIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ChatBubbleLeftRightIcon,
  UserMinusIcon,
  EyeSlashIcon,
  EnvelopeIcon,
  PhotoIcon,
  BriefcaseIcon,
  ArchiveBoxIcon,
  BuildingOffice2Icon
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import Conversation from './Conversation';
const UnresolvedIcon = require('@heroicons/react/24/outline/CheckCircleIcon');
const ResolvedIcon = require('@heroicons/react/24/solid/CheckCircleIcon');

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const oldnavigation = [
  { name: 'Dashboard', href: '/dashboard', path: '/dashboard', icon: HomeIcon, current: false },
  { name: 'Conversations', href: '/conversations', path: '/conversations', icon: ChatBubbleLeftRightIcon, current: false, count: 0 },
  { name: 'Inbox', href: '/inbox', path: '/inbox', icon: EnvelopeIcon, current: false, count: 0 },
  { name: 'Archive', href: '/archive', path: '/archive', icon: ArchiveBoxIcon, current: false, count: 0 },
  { name: 'Team', href: '/team', path: '/team', icon: UsersIcon, current: false }
]
const teams = [
  { id: 1, name: 'Chris', href: '#', initial: 'CT', current: false, active: true },
  { id: 2, name: 'Ellice', href: '#', initial: 'ES', current: false, active: true },
  { id: 3, name: 'James', href: '#', initial: 'JM', current: false, active: true }
]
const userNavigation = [
  { name: 'Your profile', href: '/profile' },
  { name: 'Sign out', href: '' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

async function updateConversation(object){
  axios.patch(`/conversation/${object._id}`, object)
      .then(res => console.log(res.data))
      .catch(error => {
        console.log(error);
  });
}

export default function Nav({ conversationObject,openMediaPanel,children,name,channel,hours,minutes }) {

  const {auth} = useAuth();

  const logout = useLogout();

  const [user, setUser] = useState({});

  const [navigation, setNavigation] = useState([
    { name: 'Dashboard', href: '/dashboard', path: '/dashboard', icon: HomeIcon, current: false },
    { name: 'Conversations', href: '/conversations', path: '/conversations', icon: ChatBubbleLeftRightIcon, current: false, count: 0 },
    { name: 'Inbox', href: '/inbox', path: '/inbox', icon: EnvelopeIcon, current: false, count: 0 },
    { name: 'Archive', href: '/archive', path: '/archive', icon: ArchiveBoxIcon, current: false, count: 0 }
  ]);

  useEffect(() => {
    if(auth){
      setUser(auth);
    }
  }, [auth])

  useEffect(() => {
    if(user?.role && user?.role == 'Admin' || user?.role == 'Sudo'){
      let tempNav = navigation;
      let link = {
         name: 'Team', href: '/team', path: '/team', icon: UsersIcon, current: false
      }
      tempNav.push(link);
      setNavigation(tempNav);
    }
    if(user?.role && user?.role == 'Sudo'){
      let tempNav = navigation;
      let onboard = {
        name: 'Onboard', href: '/onboard', path: '/onboard', icon: BriefcaseIcon, current: false
      }
      let companies = {
        name: 'Companies', href: '/companies', path: '/companies', icon: BuildingOffice2Icon, current: false
      }
      tempNav.push(onboard);
      tempNav.push(companies);
      setNavigation(tempNav);
    }
  }, [user])


  let time;
  const [isConversation, setIsConversation] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [resolved, setResolved] = useState(false);
  const [newConversationObject,setNewConversationObject] = useState({});
  const location = useLocation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [inbox,setInbox] = useState(0);
  const [conversations, setConversations] = useState(0);
  const [team, setTeam] = useState([]);

  useEffect(() => {
    setNewConversationObject(conversationObject);
    if (conversationObject?.resolved && conversationObject.resolved === true) {
      setResolved(true);
    } else {
      setResolved(false);
    }
  }, [resolved, conversationObject, newConversationObject]);

  const onLogoutClick = async () => {
    await logout();
    navigate('/login');
  }

  useEffect(() => {
    let string = location?.pathname;
    const pathnames = string.split('/');

    if(pathnames[1] ==  'conversation'){
      setIsConversation(true);
    }
  }, [location,isConversation]);

  const updateResolved = (value) => {
    if (newConversationObject) {
      if (value === true) {
        newConversationObject.resolved = true;
        newConversationObject.status = 'resolved';
        setNewConversationObject({ ...newConversationObject });
        updateConversation(newConversationObject);
        navigate(-1);
      } else {
        newConversationObject.resolved = false;
        newConversationObject.status = null;
        setNewConversationObject({ ...newConversationObject });
        updateConversation(newConversationObject);
      }
    }
  };

  const unreadConversation = (value) => {
    if(newConversationObject){
      if(value == true){
        newConversationObject.unread = value;
        setNewConversationObject({ ...newConversationObject });
        updateConversation(newConversationObject);
        navigate(-1);
      } else {
        newConversationObject.unread = value;
        setNewConversationObject({ ...newConversationObject });
        updateConversation(newConversationObject);
      }
    }
  }

  useEffect(() => {
  
    let isSubscribed = true;
  
    const fetchCounts = async () => {
      try {
        const response = await axios.get(`/conversations/count/${auth.companyId}/${user?.username}`);
        
        if (isSubscribed) {
          let tempNav = [...navigation];
          tempNav[1].count = response.data.conversations;
          tempNav[2].count = response.data.inbox;
          setNavigation(tempNav);
        }
      } catch (error) {
        console.error('Error fetching count:', error);
      }
    };

    if(user?.username) {
      fetchCounts();
    }
  
    return () => {
      isSubscribed = false;
    };
  }, [user]);

  useEffect(() => {
  
    const fetchTeam = async () => {
      try {
        const response = await axios.get(`users/active/${user?.companyId}`);
        
        setTeam(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching team:', error);
      }
    };

    if(user?.companyId) {
      fetchTeam();
    }
  
    fetchTeam();
  }, [user]);
  


  if(channel == 'iMessage'){
    time = "";
  } else if (hours == undefined || minutes == undefined) {
    time = '';
  } else if(hours == 0 && minutes == 0) {
    time = "Session expired";
  } else {
    if(hours < 2){
      if (hours < 1){
        time = `${minutes}m left`;
      } else {
        time = `${hours}h ${minutes}m left`;
      }
    } else {
      time = `${hours}h left`;
    }
  }
  
  for (let i = 0; i < navigation.length; i++) {
    if (navigation[i].path === location.pathname) {
      navigation[i].current = true;
    } else {
      navigation[i].current = false;
    }
  }

  return (
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <div>
                          <img
                            className="h-auto mt-2 w-auto"
                            src={logo}
                            alt="What Media"
                          />
                      </div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-800 text-white'
                                      : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                  {item.count ? (
                                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-700 px-2 py-1 text-xs font-medium text-white">
                                    {item.count}
                                    </span>
                                  ) : null}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                          {team.map((member) => (
                              <li key={member._id}>
                                <a
                                  href={""}
                                  className='text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-semibold'
                                >
                                  

                                  <span className="inline-flex relative h-8 w-8">
                                    <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
                                    <span className="flex items-center justify-center w-full h-full rounded-full bg-gray-500">
                                      <span className="text-xs font-medium leading-none text-white">
                                        {member.firstname[0] + member.lastname[0]}
                                      </span>
                                    </span>
                                  </span>                        
                                  <span className="truncate">{member.firstname}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <a
                            href="/support"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                          >
                            <QuestionMarkCircleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                            Support
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-auto mt-2 w-auto"
                src={logo}
                alt="What Media"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:text-white hover:bg-gray-800',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          {item.name}
                          {item.count ? (
                            <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-700 px-2 py-1 text-xs font-medium text-white">
                            {item.count}
                            </span>
                              
                          ) : null}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Users</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {team.map((member) => (
                      <li key={member._id}>
                        <a
                          href={""}
                          className='text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        >
                          

                          <span className="inline-flex relative h-8 w-8">
                            <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
                            <span className="flex items-center justify-center w-full h-full rounded-full bg-gray-500">
                              <span className="text-xs font-medium leading-none text-white">
                                {member.firstname[0] + member.lastname[0]}
                              </span>
                            </span>
                          </span>                        
                          <span className="truncate">{member.firstname}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                    href="/support"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  >
                    <QuestionMarkCircleIcon className="h-6 w-6 shrink-0" aria-hidden="true" />
                    Support
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {isConversation && (
              <>
              <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
              <div className="flex justify-start flex-initial gap-x-4 self-stretch lg:gap-x-6">
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <h1 className="text-lg font-semibold truncate ...">{name}</h1>
                </div>
              </div>
  
              <div className="flex justify-start flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <div className="flex items-center gap-x-4 lg:gap-x-6">
                  <h1 title={`You have ${hours} hours and ${minutes} minutes left to chat with ${name} before this WhatsApp conversation expires.`} className="text-md truncate ...">{time}</h1>
                </div>
                <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500" title="View all media">
                  <span className="sr-only">View all media</span>
                  <PhotoIcon onClick={openMediaPanel} className="h-6 w-6" aria-hidden="true" />
                </button>
  
                {resolved == true ? (
                  <button onClick={() => updateResolved(false)} type="button" className="-m-2.5 p-2.5 text-green-500 hover:text-gray-500" title="Mark as resolved">
                    <span className="sr-only">Mark as unresolved</span>
                    <ResolvedIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                ) : (
                  <button onClick={() => updateResolved(true)} type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500" title="Mark as unresolved">
                    <span className="sr-only">Mark as resolved</span>
                    <UnresolvedIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                )}
                
              </div>
              </>
            )}      

            <div className="flex justify-end flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* Separator */}
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                    <span className="text-sm font-medium leading-none text-white">{user?.firstname ? user?.firstname[0] + user?.lastname[0]: '...'}</span>
                  </span>
                    
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {user?.firstname ? user?.firstname + " " + user?.lastname : 'Loading...'}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                          <a href="/profile" className='block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer hover:bg-gray-100'>
                              Your profile
                          </a>
                      </Menu.Item>
                      <Menu.Item>
                          <button onClick={onLogoutClick} className='block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer hover:bg-gray-100'>
                              Sign out
                          </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">{children}</div>
          </main>
        </div>
      </div>
  )
}
